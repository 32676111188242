import { sitePhrasing } from "../../../../Language/languageHandler";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import { ArrayFieldInput } from "../../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import {
  annualReportDisclaimer1,
  annualReportDisclaimer2,
  blankRegisteredAgentFn,
  iAgreeText,
  registeredAgentFields,
  termAndConditionAcceptText,
  yesOrNoValues,
} from "../PurchaseConstants";
import { forcedRegistered, forcedStatePrincipal } from "../PurchaseFormHelpers";

export default function NorthCarolinaAnnualReportFields(props: {
  errors: any;
  touched: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
}) {
  const { errors, touched, values, setFieldValue } = props;

  return (
    <>
      <RadioInputField
        fieldName={"annualReport.veteranOwned"}
        fieldLabel={
          "Is 50% or more of your company owned by a U.S. military veteran?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.veteranOwned === "yes" && (
        <RadioInputField
          fieldName={"annualReport.disabledVeteranOwned"}
          fieldLabel={
            "Is 50% or more of your company owned by a disabled U.S. military veteran?"
          }
          errors={errors}
          required={true}
          touched={touched}
          options={yesOrNoValues}
        />
      )}
      <RadioInputField
        fieldName={"annualReport.netReceiptsUnder1M"}
        fieldLabel={
          "Are the net receipts of this company less than $1,000,000?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      <TextInputField
        fieldName={"annualReport.natureOfBusiness"}
        fieldLabel={"Briefly describe the nature of the business"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Briefly describe the nature of the business"}
      />

      <h3>Do you need to change any of the following information?</h3>
      <RadioInputField
        fieldName={"annualReport.informationChange.principalAddress.changed"}
        fieldLabel={"Principal Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.principalAddress.changed ===
        "yes" && (
        <AddressInputFieldGroup
          fieldName={
            "annualReport.informationChange.principalAddress.newAddress"
          }
          errors={errors}
          touched={touched}
          // fieldMessages={{
          //     state: forcedStatePrincipal[sitePhrasing.state] ? `Your principal address must be located in ${sitePhrasing.state}.` : ``
          // }}
          // disableFields={{
          //     state: forcedStatePrincipal[sitePhrasing.state] ? true : false,
          // }}
        />
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.mailingAddress.changed"}
        fieldLabel={"Mailing Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.mailingAddress.changed ===
        "yes" && (
        <AddressInputFieldGroup
          fieldName={"annualReport.informationChange.mailingAddress.newAddress"}
          errors={errors}
          touched={touched}
        />
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
        fieldLabel={"Registered Agent?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.registeredAgent.agentChange ===
        "yes" && (
        <>
          <TextInputField
            fieldName={
              "annualReport.informationChange.registeredAgent.newAgent"
            }
            fieldLabel={"Registered Agent Name"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"Registered Agent Name"}
          />

          <TextInputField
            fieldName={"annualReport.informationChange.registeredAgent.email"}
            fieldLabel={"Registered Agent Email"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"Registered Agent Email"}
          />
        </>
      )}

      <RadioInputField
        fieldName={
          "annualReport.informationChange.registeredAgent.agentAddressChange"
        }
        fieldLabel={"Registered Agent Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
        onChange={(e: any) => {
          setFieldValue(
            "annualReport.informationChange.registeredAgent.agentAddressChange",
            e.target.value
          );
          setFieldValue(
            "annualReport.informationChange.registeredAgent.agentMailingAddressChange",
            ""
          );
          setFieldValue(
            "annualReport.informationChange.registeredAgent.agentStreetAddressChange",
            ""
          );
        }}
      />

      {values.annualReport.informationChange.registeredAgent
        .agentAddressChange === "yes" && (
        <>
          <RadioInputField
            fieldName={
              "annualReport.informationChange.registeredAgent.agentMailingAddressChange"
            }
            fieldLabel={"Registered Agent Mailing Address?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.registeredAgent
            .agentMailingAddressChange === "yes" && (
            <AddressInputFieldGroup
              fieldName={
                "annualReport.informationChange.registeredAgent.newAddress"
              }
              errors={errors}
              touched={touched}
              fieldMessages={{
                state: forcedRegistered[sitePhrasing.state]
                  ? `Your registered agent must be located in ${sitePhrasing.state}.`
                  : ``,
              }}
              disableFields={{
                state: forcedRegistered[sitePhrasing.state] ? true : false,
              }}
            />
          )}

          <RadioInputField
            fieldName={
              "annualReport.informationChange.registeredAgent.agentStreetAddressChange"
            }
            fieldLabel={"Registered Agent Street Address?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.registeredAgent
            .agentStreetAddressChange === "yes" && (
            <>
              <AddressInputFieldGroup
                fieldName={
                  "annualReport.informationChange.registeredAgent.newStreetAddress"
                }
                errors={errors}
                touched={touched}
                fieldMessages={{
                  state: forcedRegistered[sitePhrasing.state]
                    ? `Your registered agent must be located in ${sitePhrasing.state}.`
                    : ``,
                }}
                disableFields={{
                  state: forcedRegistered[sitePhrasing.state] ? true : false,
                }}
              />
              <TextInputField
                fieldName={
                  "annualReport.informationChange.registeredAgent.newStreetAddress.county"
                }
                fieldLabel={"County"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"County"}
              />
            </>
          )}
        </>
      )}

      <RadioInputField
        fieldName={
          "annualReport.informationChange.authorizedPerson.authorizedPersonChange"
        }
        fieldLabel={
          sitePhrasing.state === "Florida"
            ? `Person(s) Authorized to manage the ${sitePhrasing.business}?`
            : "Company Officials?"
        }
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.authorizedPerson
        .authorizedPersonChange === "yes" && (
        <>
          <RadioInputField
            fieldName={
              "annualReport.informationChange.authorizedPerson.removingAgents"
            }
            fieldLabel={`Are you removing any ${
              sitePhrasing.state === "Florida" ? "agents" : "Company Officials"
            }?`}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.authorizedPerson
            .removingAgents === "yes" && (
            <ArrayFieldInput
              fieldName={
                "annualReport.informationChange.authorizedPerson.removedAgents"
              }
              values={
                values.annualReport.informationChange.authorizedPerson
                  .removedAgents
              }
              blankEntryFn={blankRegisteredAgentFn}
              fields={registeredAgentFields}
              touched={touched}
              errors={errors}
              removeText={"- Click to remove entry"}
              addText={"+ Click to add an entry"}
            />
          )}

          <RadioInputField
            fieldName={
              "annualReport.informationChange.authorizedPerson.addingAgents"
            }
            fieldLabel={`Are you adding any ${
              sitePhrasing.state === "Florida" ? "agents" : "Company Officials"
            }?`}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.authorizedPerson
            .addingAgents === "yes" && (
            <ArrayFieldInput
              fieldName={
                "annualReport.informationChange.authorizedPerson.addedAgents"
              }
              values={
                values.annualReport.informationChange.authorizedPerson
                  .addedAgents
              }
              blankEntryFn={blankRegisteredAgentFn}
              fields={registeredAgentFields}
              touched={touched}
              errors={errors}
              removeText={"- Click to remove entry"}
              addText={"+ Click to add an entry"}
            />
          )}
        </>
      )}

      <CheckboxInputField
        fieldName={"annualReport.annualAgree"}
        fieldLabel={annualReportDisclaimer1}
        touched={touched}
        errors={errors}
      />

      <CheckboxInputField
        fieldName={"iAgree"}
        fieldLabel={iAgreeText}
        touched={touched}
        errors={errors}
      />

      <CheckboxInputField
        fieldName={"termAndConditionAgree"}
        fieldLabel={
          <span
            dangerouslySetInnerHTML={{
              __html: termAndConditionAcceptText,
            }}
          />
        }
        touched={touched}
        errors={errors}
      />

      <TextInputField
        fieldName={"annualReport.electronicSignature"}
        fieldLabel={"Name of Individual Signing - Electronic Signature"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Electronic Signature"}
        customClass={"signature-font"}
      />

      <TextInputField
        fieldName={"annualReport.title"}
        fieldLabel={"Title"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Title"}
      />

      <CheckboxInputField
        fieldName={"annualReport.iAmAuthorized"}
        fieldLabel={`The above company is the entity I intend to file an annual report for. I am authorized to file an annual report for the above company`}
        touched={touched}
        errors={errors}
      />
    </>
  );
}
