import { sitePhrasing } from "../../../Language/languageHandler";
import { AddressInputFieldGroup } from "../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../FormFields/RadioInputField/RadioInputField";
import SelectFieldInput from "../../FormFields/SelectInputField/SelectInputField";
import TextInputField from "../../FormFields/TextInputField/TextInputField";
import FloridaAnnualReportFields from "./AnnualReportStateForms/FloridaAnnualReportFields";
import IndianaAnnualReportFields from "./AnnualReportStateForms/IndianaAnnualReportFields";
import MississippiAnnualReportFields from "./AnnualReportStateForms/MississippiAnnualReportFields";
import NorthCarolinaAnnualReportFields from "./AnnualReportStateForms/NorthCarolinaAnnualReportFields";
import TennesseeAnnualReportFields from "./AnnualReportStateForms/TennesseeAnnualReportFields";
import {
  annualReportDisclaimer1,
  annualReportDisclaimer2,
  annualReportTypeOfBusiness,
  iAgreeText,
  termAndConditionAcceptText,
  yesOrNoValues,
} from "./PurchaseConstants";
import { forcedRegistered, forcedStatePrincipal } from "./PurchaseFormHelpers";

export const AnnualReportFields = (props: {
  errors: any;
  touched: any;
  values: any;
  selectedProducts: any;
  setFieldValue: any;
}) => {
  const {
    values,
    errors,
    touched,
    selectedProducts = {},
    setFieldValue,
  } = props;

  let annualPhrasing = "annual report";

  if (selectedProducts["amend"]) {
    annualPhrasing = "amendment";
  }

  if (selectedProducts["reinstate"]) {
    annualPhrasing = "reinstatement";
  }

  if (sitePhrasing.state === "Florida") {
    return (
      <FloridaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        annualPhrasing={annualPhrasing}
        setFieldValue={setFieldValue}
      />
    );
  }

  if (sitePhrasing.state === "Indiana") {
    return (
      <IndianaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
      />
    );
  }

  if (sitePhrasing.state === "Mississippi") {
    return (
      <MississippiAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
      />
    );
  }

  if (sitePhrasing.state === "North Carolina") {
    return (
      <NorthCarolinaAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
      />
    );
  }

  if (sitePhrasing.state === "Tennessee") {
    return (
      <TennesseeAnnualReportFields
        errors={errors}
        touched={touched}
        values={values}
        selectedProducts={selectedProducts}
        setFieldValue={setFieldValue}
      />
    );
  }

  return (
    <>
      <RadioInputField
        fieldName={"annualReport.typeOfEntity"}
        fieldLabel={"What type of entity is your company?"}
        errors={errors}
        required={true}
        touched={touched}
        options={annualReportTypeOfBusiness}
      />
      {values.annualReport.typeOfEntity === "Corp, INC, other" && (
        <>
          <h3>Please provide the following information:</h3>
          <TextInputField
            fieldName={"annualReport.entityCEO"}
            fieldLabel={"CEO of your company"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"CEO of your company"}
          />
          <TextInputField
            fieldName={"annualReport.entityCFO"}
            fieldLabel={"CFO of your company"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"CFO of your company"}
          />
          <TextInputField
            fieldName={"annualReport.entitySecretary"}
            fieldLabel={"Secretary of your company"}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={"Secretary of your company"}
          />
        </>
      )}

      <h3>Do you need to change any of the following information?</h3>
      <RadioInputField
        fieldName={"annualReport.informationChange.principalAddress.changed"}
        fieldLabel={"Principal Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.principalAddress.changed ===
        "yes" && (
        <AddressInputFieldGroup
          fieldName={
            "annualReport.informationChange.principalAddress.newAddress"
          }
          errors={errors}
          touched={touched}
          // fieldMessages={{
          //   state: forcedStatePrincipal[sitePhrasing.state] ? `Your principal address must be located in ${sitePhrasing.state}.` : ``
          // }}
          // disableFields={{
          //   state: forcedStatePrincipal[sitePhrasing.state] ? true : false,
          // }}
        />
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
        fieldLabel={"Registered Agent?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.registeredAgent.agentChange ===
        "yes" && (
        <TextInputField
          fieldName={"annualReport.informationChange.registeredAgent.newAgent"}
          fieldLabel={"Registered Agent Name"}
          errors={errors}
          touched={touched}
          required={true}
          placeholder={"Registered Agent Name"}
        />
      )}

      <RadioInputField
        fieldName={
          "annualReport.informationChange.registeredAgent.agentAddressChange"
        }
        fieldLabel={"Registered Agent Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.registeredAgent
        .agentAddressChange === "yes" && (
        <AddressInputFieldGroup
          fieldName={
            "annualReport.informationChange.registeredAgent.newAddress"
          }
          errors={errors}
          touched={touched}
          fieldMessages={{
            state: forcedRegistered[sitePhrasing.state]
              ? `Your registered agent must be located in ${sitePhrasing.state}.`
              : ``,
          }}
          disableFields={{
            state: forcedRegistered[sitePhrasing.state] ? true : false,
          }}
        />
      )}

      <RadioInputField
        fieldName={
          "annualReport.informationChange.emailAddress.emailAddressChange"
        }
        fieldLabel={"E-Mail address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.emailAddress.emailAddressChange ===
        "yes" && (
        <TextInputField
          fieldName={
            "annualReport.informationChange.emailAddress.newEmailAddress"
          }
          fieldLabel={"Enter your new COMPLETE e-mail address"}
          errors={errors}
          touched={touched}
          required={true}
          placeholder={"Enter your new COMPLETE e-mail address"}
        />
      )}

      <CheckboxInputField
        fieldName={"annualReport.annualAgree"}
        fieldLabel={annualReportDisclaimer1}
        touched={touched}
        errors={errors}
      />

      {sitePhrasing.state !== "Mississippi" &&
        sitePhrasing.state !== "South Carolina" && (
          <>
            <CheckboxInputField
              fieldName={"iAgree"}
              fieldLabel={iAgreeText}
              touched={touched}
              errors={errors}
            />

            <CheckboxInputField
              fieldName={"termAndConditionAgree"}
              fieldLabel={
                <span
                  dangerouslySetInnerHTML={{
                    __html: termAndConditionAcceptText,
                  }}
                />
              }
              touched={touched}
              errors={errors}
            />
          </>
        )}

      <TextInputField
        fieldName={"annualReport.electronicSignature"}
        fieldLabel={"Name of Individual Signing - Electronic Signature"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Electronic Signature"}
        customClass={"signature-font"}
      />

      <SelectFieldInput
        fieldName={"annualReport.title"}
        fieldLabel={"Title"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"- Select Title -"}
        options={GeorgiaAnnualTitleValues}
      />

      <CheckboxInputField
        fieldName={"annualReport.iAmAuthorized"}
        fieldLabel={annualReportDisclaimer2}
        touched={touched}
        errors={errors}
      />
    </>
  );
};

const GeorgiaAnnualTitleValues = [
  { value: "Organizer", label: "Organizer" },
  { value: "Member", label: "Member" },
  { value: "Manager", label: "Manager" },
  { value: "Attorney In Fact", label: "Attorney In Fact" },
  { value: "Court-Appointed Fiduciary", label: "Court-Appointed Fiduciary" },
  { value: "Registered Agent", label: "Registered Agent" },
  { value: "Authorized Person", label: "Authorized Person" },
];
