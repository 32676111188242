import * as yup from "yup";
import { sitePhrasing } from "../../../Language/languageHandler";
import {
  basicPrimaryActivityKeys,
  basicPrimaryActivityPreKeys,
  constructionPrimaryBusinessActivityDescriptionOptionsSpecifyReq,
  healthCarePrimaryBusinessActivityDescriptionOptionsSpecifyReq,
  retailPrimaryBusinessActivitySpecifyRef,
} from "./EinTaxValueConstants";
import { StateAnnualValidation } from "./AnnualReportStateForms/StateSpecificAnnualReportFields";

export const generatePurchaseFormInitialValues = (
  selectedProducts: any,
  options: any = {}
) => {
  const {
    annualBusinessType = "", // Mississippi Only
  } = options;
  return {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    referenceNumber: "",
    businessName: selectedProducts["legalNoticeAdvertisement"] ? "-" : "",
    iAgree: false,
    termAndConditionAgree: false,
    generalAgree: false,
    electronicSignature: "",

    generalAddress: {
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },

    fictitiousNameRenewal: {
      tradeNameDBA: "",
      isThisYourInformation: "",
      informationStillAccurate: "",

      updateAddEin: "",
      feiEin: "",

      changeOwner: "",
      deleteCurrentOwner: "",
      newOwners: [],

      updateCurrentAddress: "",
      updatedCurrentAddress: {
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
      changedOwnerAddress: {
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },

      sunbizPayload: {},

      fictitiousNameAgreement: false,
      tellingTruth: false,
      iAmAuthorized: false,
      digitalSignature: "",
    },
    einTaxInfo: {
      legalStructure: "", //sitePhrasing.state === 'Florida' ? 'LLC' : '',
      partnershipType: "",
      membersInLLC: "",
      whyRequesting: "",
      responsibleParty: {
        firstName: "",
        middleName: "",
        lastName: "",
        suffix: "",
        ssnItin: "",
        ssnOrItin: "",
      },
      physicalLocation: {
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
      differentAddressInfo: {
        differentAddress: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },
      about: {
        legalName: "",
        tradeNameDBA: "",
        county: "",
        fileDate: "",
        closingMonthAccountingPeriod: "",
        highwayVehicle: "",
        gamblingOrWagering: "",
        form720: "",
        alcoholTobaccoFirearms: "",
        w2Info: {
          expectW2: "",
          firstDateWages: "",
          highestAgricultureEmployeeCount: "",
          highestEmployeeCount: "",
          taxLiableLessThan1k: "",
        },
      },
      primaryBusinessActivity: "",
      primaryBusinessActivityPreDescription: "", // validate
      primaryBusinessActivityDescription: "", // validate
      primaryBusinessActivitySpecifyType: "", // Validate
      primaryBusinessActivitySpecify: "",
      einAgreement: false,
      digitalSignature: "",
    },
    annualReport: {
      // Florida Only Fields
      sunbizPayload: {},
      isThisYourInformation: "",
      informationStillAccurate: "",

      // Florida & Indiana & Mississippi Only Fields
      feiEin: "",
      feiEinStatus: "",

      // Mississippi Only Fields
      useSSN: false,
      hasWrittenOperatingAgreement: false,
      nonProfitMembers: "",
      isNonProfit501c3: "",
      nonProfitSolicitDonations: "",
      purposeOfNonProfit: "",
      signerAddress: {
        address: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      },

      // Georgia Only Fields
      typeOfEntity: annualBusinessType ? annualBusinessType : "",
      entityCEO: "",
      entityCFO: "",
      entitySecretary: "",

      // North Carolina Only Fields
      veteranOwned: "",
      disabledVeteranOwned: "",
      netReceiptsUnder1M: "",
      natureOfBusiness: "",

      // Tennessee Only Fields
      membersWithStake: "",

      // All States may use some of these fields
      informationChange: {
        principalAddress: {
          changed: "",
          newAddress: {
            address: "",
            address2: "",
            city: "",
            state: "",
            // state: forcedStatePrincipal[sitePhrasing.state] ? Object.keys(forcedStatePrincipal[sitePhrasing.state])[0].toUpperCase() : '',
            zip: "",
          },
        },
        mailingAddress: {
          changed: sitePhrasing.state === "Georgia" ? "no" : "",
          newAddress: {
            address: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
          },
        },

        changeOfficers: "",

        registeredAgent: {
          agentChange: sitePhrasing.state === "Mississippi" ? "no" : "",

          agentName: "",
          title: "",

          newAgent: "",
          agentAddressChange: "",

          agentMailingAddressChange: "",
          agentStreetAddressChange: "",

          agentChangeType: "",
          agentType: "",

          firstName: "",
          lastName: "",
          email: "",
          businessName: "",

          newAddress: {
            address: "",
            address2: "",
            city: "",
            state: forcedRegistered[sitePhrasing.state]
              ? Object.keys(
                  forcedRegistered[sitePhrasing.state]
                )[0].toUpperCase()
              : "",
            zip: "",
          },

          newStreetAddress: {
            address: "",
            address2: "",
            city: "",
            state: forcedRegistered[sitePhrasing.state]
              ? Object.keys(
                  forcedRegistered[sitePhrasing.state]
                )[0].toUpperCase()
              : "",
            zip: "",
            county: "",
          },
        },
        authorizedPerson: {
          authorizedPersonChange: sitePhrasing.state === "Georgia" ? "no" : "",
          removingAgents: "",
          removedAgents: [],
          addingAgents: "",
          addedAgents: [],

          changingAddress: "",
          changedAddress: {
            address: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
          },
        },

        llcManager: {
          llcManagerChange: "",
          removingAgents: "",
          removedAgents: [],
          addingAgents: "",
          addedAgents: [],
        },

        emailAddress: {
          emailAddressChange: "",
          newEmailAddress: "",
        },

        NAICS: {
          changeCodes: "",
          code1: "",
          code2: "",
          code3: "",
        },
      },

      noPublicRecordExemption: false,
      tellingTruth: false,
      iAmAuthorized: false,
      annualAgree: false,
      informationAccurate: false,
      electronicSignature: "",
      title: "",
    },
  };
};

const referenceErrorMessage = sitePhrasing.referenceErrorMessage
  ? sitePhrasing.referenceErrorMessage
  : `Reference number must be exactly ${sitePhrasing.referenceLength} characters long.`;
const referenceValidation: any = sitePhrasing.referenceValidation
  ? yup
      .string()
      .required()
      .matches(sitePhrasing.referenceValidation, referenceErrorMessage)
  : yup
      .string()
      .required("Required")
      .length(Number(sitePhrasing.referenceLength), referenceErrorMessage);

export const forcedStatePrincipal: any = {
  "North Carolina": {
    nc: true,
    "north carolina": true,
  },
  Georgia: {
    ga: true,
    georgia: true,
  },
};

export const forcedRegistered: any = {
  Florida: {
    fl: true,
    florida: true,
  },
  "North Carolina": {
    nc: true,
    "north carolina": true,
  },
  Georgia: {
    ga: true,
    georgia: true,
  },
  Mississippi: {
    ms: true,
    mississippi: true,
  },
  Tennessee: {
    tn: true,
    tennessee: true,
  },
};

export const generatePurchaseValidationSchema = (selectedProducts: any) =>
  yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup
      .string()
      .required("Required")
      .email("Please enter a valid email"),
    phoneNumber: yup
      .string()
      .required()
      .matches(
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
        "Invalid phone number"
      ),
    referenceNumber: referenceValidation,

    businessName: yup.string().required("Required"),
    iAgree: yup.bool().required().oneOf([true], "Must be checked"),

    ...(sitePhrasing.state === "Tennessee" &&
    selectedProducts["certificateOfStatus"] &&
    !selectedProducts["annualReport"] &&
    !selectedProducts["reinstate"] &&
    !selectedProducts["amend"] &&
    !selectedProducts["einTaxId"]
      ? {
          generalAddress: yup.object().shape({
            address: yup.string().required("Required"),
            city: yup.string().required("Required"),
            state: yup.string().required("Required"),
            zip: yup.string().required("Required"),
          }),
        }
      : {}),

    ...(((sitePhrasing.state === "Florida" &&
      (selectedProducts["fictitiousNameRenewal"] ||
        selectedProducts["annualReport"] ||
        selectedProducts["legalNoticeAdvertisement"])) ||
    sitePhrasing.state === "Indiana" ||
    ((sitePhrasing.state === "Tennessee" ||
      sitePhrasing.state === "Mississippi") ||
      (selectedProducts["annualReport"]) ||
    selectedProducts["einTaxId"] ||
    selectedProducts["laborLawPoster"] ||
    selectedProducts["certificateOfStatus"]))
      ? {
          termAndConditionAgree: yup
            .bool()
            .required()
            .oneOf([true], "Must be checked"),
        }
      : {}),

    // ...(selectedProducts["certificateOfStatus"]
    //   ? {
    //       termAndConditionAgree: yup.bool(),
    //     }
    //   : {}),

    // ...(sitePhrasing.state === "South Carolina" &&
    // !selectedProducts["annualReport"] &&
    // !selectedProducts["fictitiousNameRenewal"]
    //   ? {
    //       termAndConditionAgree: yup.bool(),
    //     }
    //   : {}),

    ...(selectedProducts["fictitiousNameRenewal"]
      ? {
          fictitiousNameRenewal: yup.object().shape({
            tradeNameDBA: yup.string().required("Required"),
            isThisYourInformation: yup.string().required("Required"),
            informationStillAccurate: yup.string().required("Required"),

            updateAddEin: yup.string().when("informationStillAccurate", {
              is: "no",
              then: yup.string().required("Required"),
            }),
            feiEin: yup.string().when("updateAddEin", {
              is: "yes",
              then: yup
                .string()
                .required("Please enter a valid FEI/EIN (9 digits)")
                .length(10, "Must be 9 digits")
                .matches(/[0-9-]{10}/g, "Must be only digits"),
            }),

            changeOwner: yup.string().when("informationStillAccurate", {
              is: "no",
              then: yup.string().required("Required"),
            }),
            deleteCurrentOwner: yup.string().when("changeOwner", {
              is: "yes",
              then: yup.string().required("Required"),
            }),
            newOwners: yup.array().when(["changeOwner", "deleteCurrentOwner"], {
              is: (a: string, b: string) => {
                return a === "yes" && b === "yes";
              },
              then: yup
                .array()
                .of(
                  yup.object().shape({
                    ownerType: yup.string().required("Required"),
                    firstName: yup.string().when("ownerType", {
                      is: "person",
                      then: yup.string().required("Required"),
                    }),
                    lastName: yup.string().when("ownerType", {
                      is: "person",
                      then: yup.string().required("Required"),
                    }),

                    businessName: yup.string().when("ownerType", {
                      is: "business",
                      then: yup.string().required("Required"),
                    }),
                    feiEin: yup.string().when("feiEinStatus", {
                      is: "listed",
                      then: yup
                        .string()
                        .required("Please enter a valid FEI/EIN (9 digits)")
                        .length(10, "Must be 9 digits")
                        .matches(/[0-9-]{10}/g, "Must be only digits"),
                    }),
                    feiEinStatus: yup.string().when("ownerType", {
                      is: "business",
                      then: yup.string().required("Required"),
                    }),

                    address: yup.string().required("Required"),
                    city: yup.string().required("Required"),
                    state: yup.string().required("Required"),
                    zip: yup.string().required("Required"),
                  })
                )
                .min(1, "Please add an entry"),
            }),

            updateCurrentAddress: yup
              .string()
              .when("informationStillAccurate", {
                is: "no",
                then: yup.string().required("Required"),
              }),
            updatedCurrentAddress: yup.object().when("updateCurrentAddress", {
              is: "yes",
              then: yup.object().shape({
                address: yup.string().required("Required"),
                address2: yup.string(),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              }),
            }),
            changeOwnerAddress: yup.string().when("informationStillAccurate", {
              is: "no",
              then: yup.string().required("Required"),
            }),
            changedOwnerAddress: yup.object().when("changeOwnerAddress", {
              is: "yes",
              then: yup.object().shape({
                address: yup.string().required("Required"),
                address2: yup.string(),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              }),
            }),

            fictitiousNameAgreement: yup
              .bool()
              .required()
              .oneOf([true], "Must be checked"),
            tellingTruth: yup
              .bool()
              .required()
              .oneOf([true], "Must be checked"),
            iAmAuthorized: yup
              .bool()
              .required()
              .oneOf([true], "Must be checked"),
            digitalSignature: yup.string().required("Required"),
          }),
        }
      : {}),

    ...(selectedProducts["legalNoticeAdvertisement"] &&
    !selectedProducts["fictitiousNameRenewal"]
      ? {
          einTaxInfo: yup.object().shape({
            about: yup.object().shape({
              tradeNameDBA: yup.string().required("Required"),
            }),
            physicalLocation: yup.object().shape({
              address: yup.string().required("Required"),
              city: yup.string().required("Required"),
              state: yup.string().required("Required"),
              zip: yup.string().required("Required"),
            }),
            differentAddressInfo: yup.object().shape({
              differentAddress: yup.string().required("Required"),
              address: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
              city: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
              state: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
              zip: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
            }),
            einAgreement: yup
              .bool()
              .required()
              .oneOf([true], "Must be checked"),

            digitalSignature: yup.string().required("Required"),
          }),
        }
      : {}),
    ...(selectedProducts["einTaxId"]
      ? {
          einTaxInfo: yup.object().shape({
            legalStructure: yup.string().required("Required"),
            partnershipType: yup.string().when("legalStructure", {
              is: "Partnerships",
              then: yup.string().required("Required"),
            }),
            membersInLLC: yup
              .number()
              .min(1, "Must be at least 1")
              .required("Required"),
            whyRequesting: yup.string().required("Required"),
            responsibleParty: yup.object().shape({
              firstName: yup.string().required("Required"),
              middleName: yup.string(),
              lastName: yup.string().required("Required"),
              suffix: yup.string(),
              ssnItin: yup
                .string()
                .required("Please enter a valid ssn or itin(9 digits)")
                .length(11, "Must be 9 digits")
                .matches(/[0-9-]{11}/g, "Must be only digits"),
              ssnOrItin: yup.string().required("Required"),
            }),
            physicalLocation: yup.object().shape({
              address: yup.string().required("Required"),
              city: yup.string().required("Required"),
              state: yup.string().required("Required"),
              zip: yup.string().required("Required"),
            }),
            differentAddressInfo: yup.object().shape({
              differentAddress: yup.string().required("Required"),
              address: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
              city: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
              state: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
              zip: yup.string().when("differentAddress", {
                is: "yes",
                then: yup.string().required("Required"),
              }),
            }),
            // continue EIN stuff
            about: yup.object().shape({
              legalName: yup.string().required("Required"),
              tradeNameDBA: yup.string(),
              county: yup.string().required("Required"),
              fileDate: yup.date().required("Required"),
              closingMonthAccountingPeriod: yup.string().required("Required"),
              highwayVehicle: yup.string().required("Required"),
              gamblingOrWagering: yup.string().required("Required"),
              form720: yup.string().required("Required"),
              alcoholTobaccoFirearms: yup.string().required("Required"),
              w2Info: yup.object().shape({
                expectW2: yup.string().required("Required"),
                firstDateWages: yup.date().when("expectW2", {
                  is: "yes",
                  then: yup.date().required("Required"),
                }),
                highestAgricultureEmployeeCount: yup.number().when("expectW2", {
                  is: "yes",
                  then: yup
                    .number()
                    .min(1, "Must be at least 1")
                    .required("Required"),
                }),
                highestEmployeeCount: yup.number().when("expectW2", {
                  is: "yes",
                  then: yup
                    .number()
                    .min(1, "Must be at least 1")
                    .required("Required"),
                }),
                taxLiableLessThan1k: yup.string().when("expectW2", {
                  is: "yes",
                  then: yup.string().required("Required"),
                }),
              }),
            }),

            primaryBusinessActivity: yup.string().required("Required"),

            // ACTIVITY PRE DESCRIPTION
            primaryBusinessActivityPreDescription: yup
              .string()
              .when(["primaryBusinessActivity"], {
                is: (primaryBusinessActivity: string) => {
                  if (basicPrimaryActivityPreKeys[primaryBusinessActivity]) {
                    return true;
                  }
                  return false;
                },
                then: yup.string().required("Required"),
              }),

            // ACTIVITY DESCRIPTION
            primaryBusinessActivityDescription: yup
              .string()
              .when(
                [
                  "primaryBusinessActivity",
                  "primaryBusinessActivityPreDescription",
                ],
                {
                  is: (
                    primaryBusinessActivity: string,
                    primaryBusinessActivityPreDescription: string
                  ) => {
                    if (basicPrimaryActivityKeys[primaryBusinessActivity]) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Construction" &&
                      primaryBusinessActivityPreDescription === "no"
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Health Care" &&
                      primaryBusinessActivityPreDescription !== ""
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Real Estate" &&
                      primaryBusinessActivityPreDescription !== "" &&
                      primaryBusinessActivityPreDescription !==
                        "I sell property for others" &&
                      primaryBusinessActivityPreDescription !==
                        "I manage real estate for others" &&
                      primaryBusinessActivityPreDescription !== "Other"
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Transportation" &&
                      (primaryBusinessActivityPreDescription === "Cargo" ||
                        primaryBusinessActivityPreDescription === "Passengers")
                    ) {
                      return true;
                    }
                    return false;
                  },
                  then: yup.string().required("Required"),
                }
              ),

            // SPECIFY TYPE
            primaryBusinessActivitySpecifyType: yup
              .string()
              .when(
                [
                  "primaryBusinessActivity",
                  "primaryBusinessActivityPreDescription",
                  "primaryBusinessActivityDescription",
                ],
                {
                  is: (
                    primaryBusinessActivity: string,
                    primaryBusinessActivityPreDescription: string,
                    primaryBusinessActivityDescription: string
                  ) => {
                    if (
                      primaryBusinessActivity === "Real Estate" &&
                      primaryBusinessActivityPreDescription ===
                        "I use capital to build property" &&
                      primaryBusinessActivityDescription === "no"
                    ) {
                      return true;
                    }
                    return false;
                  },
                  then: yup.string().required("Required"),
                }
              ),

            // SPECIFY
            primaryBusinessActivitySpecify: yup
              .string()
              .when(
                [
                  "primaryBusinessActivity",
                  "primaryBusinessActivityPreDescription",
                  "primaryBusinessActivityDescription",
                  "primaryBusinessActivitySpecifyType",
                ],
                {
                  is: (
                    primaryBusinessActivity: string,
                    primaryBusinessActivityPreDescription: string,
                    primaryBusinessActivityDescription: string,
                    primaryBusinessActivitySpecifyType: string
                  ) => {
                    if (
                      primaryBusinessActivity === "Other" ||
                      primaryBusinessActivity === "Manufacturing"
                    ) {
                      return true;
                    }
                    if (
                      basicPrimaryActivityKeys[primaryBusinessActivity] &&
                      primaryBusinessActivityDescription === "Other"
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Construction" &&
                      (primaryBusinessActivityPreDescription === "yes" ||
                        (primaryBusinessActivityPreDescription === "no" &&
                          constructionPrimaryBusinessActivityDescriptionOptionsSpecifyReq[
                            primaryBusinessActivityDescription
                          ]))
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Health Care" &&
                      primaryBusinessActivityPreDescription !== "" &&
                      healthCarePrimaryBusinessActivityDescriptionOptionsSpecifyReq[
                        primaryBusinessActivityDescription
                      ]
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Real Estate" &&
                      ((primaryBusinessActivityPreDescription ===
                        "I rent or lease property that I own" &&
                        primaryBusinessActivityDescription === "Other") ||
                        (primaryBusinessActivityPreDescription ===
                          "I use capital to build property" &&
                          primaryBusinessActivityDescription === "yes") ||
                        (primaryBusinessActivityPreDescription ===
                          "I use capital to build property" &&
                          primaryBusinessActivityDescription === "no" &&
                          constructionPrimaryBusinessActivityDescriptionOptionsSpecifyReq[
                            primaryBusinessActivitySpecifyType
                          ]) ||
                        primaryBusinessActivityPreDescription === "Other")
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Rental & Leasing" &&
                      (primaryBusinessActivityDescription ===
                        "I rent or lease goods" ||
                        primaryBusinessActivityDescription ===
                          "I rent, lease, or sell real estate")
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Retail" &&
                      retailPrimaryBusinessActivitySpecifyRef[
                        primaryBusinessActivityDescription
                      ]
                    ) {
                      return true;
                    }
                    if (
                      primaryBusinessActivity === "Transportation" &&
                      (primaryBusinessActivityDescription === "Other" ||
                        primaryBusinessActivityPreDescription ===
                          "I provide a support activity for transportation")
                    ) {
                      return true;
                    }
                    if (
                      (primaryBusinessActivity === "Wholesale" &&
                        primaryBusinessActivityDescription === "yes") ||
                      (primaryBusinessActivityDescription === "no" &&
                        primaryBusinessActivitySpecifyType === "no")
                    ) {
                      return true;
                    }
                    return false;
                  },
                  then: yup.string().required("Required"),
                }
              ),

            einAgreement: yup
              .bool()
              .required()
              .oneOf([true], "Must be checked"),
            digitalSignature: yup.string().required("Required"),
          }),
        }
      : {}),
    ...(selectedProducts["annualReport"] ||
    selectedProducts["reinstate"] ||
    selectedProducts["amend"]
      ? {
          annualReport: StateAnnualValidation[sitePhrasing.state]
            ? StateAnnualValidation[sitePhrasing.state]
            : standardAnnualSchema,
        }
      : {}),
  });

const standardAnnualSchema = yup.object().shape({
  ...(sitePhrasing.state === "Florida"
    ? {
        feiEin: yup.string().when("feiEinStatus", {
          is: "listed",
          then: yup
            .string()
            .required("Please enter a valid FEI/EIN (9 digits)")
            .length(10, "Must be 9 digits")
            .matches(/[0-9-]{10}/g, "Must be only digits"),
        }),
        feiEinStatus: yup.string().required("Required"),

        noPublicRecordExemption: yup
          .bool()
          .required()
          .oneOf([true], "Must be checked"),
        tellingTruth: yup.bool().required().oneOf([true], "Must be checked"),
        iAmAuthorized: yup.bool().required().oneOf([true], "Must be checked"),
        annualAgree: yup.bool().required().oneOf([true], "Must be checked"),
      }
    : {}),

  ...(sitePhrasing.state === "Indiana"
    ? {
        feiEin: yup.string().when("feiEinStatus", {
          is: "listed",
          then: yup
            .string()
            .required("Please enter a valid FEI/EIN (9 digits)")
            .length(10, "Must be 9 digits")
            .matches(/[0-9-]{10}/g, "Must be only digits"),
        }),
        feiEinStatus: yup.string().required("Required"),

        tellingTruth: yup.bool().required().oneOf([true], "Must be checked"),
        iAmAuthorized: yup.bool().required().oneOf([true], "Must be checked"),
        annualAgree: yup.bool().required().oneOf([true], "Must be checked"),
      }
    : {}),

  ...(sitePhrasing.state === "North Carolina"
    ? {
        // add proper validation for NC
        veteranOwned: yup.string().required("Required"),
        disabledVeteranOwned: yup.string().when("veteranOwned", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
        netReceiptsUnder1M: yup.string().required("Required"),
        natureOfBusiness: yup.string().required("Required"),
      }
    : {}),

  ...(sitePhrasing.state === "Georgia"
    ? {
        typeOfEntity: yup.string().required("Required"),
        entityCEO: yup.string().when("typeOfEntity", {
          is: "Corp, INC, other",
          then: yup.string().required("Required"),
        }),
        entityCFO: yup.string().when("typeOfEntity", {
          is: "Corp, INC, other",
          then: yup.string().required("Required"),
        }),
        entitySecretary: yup.string().when("typeOfEntity", {
          is: "Corp, INC, other",
          then: yup.string().required("Required"),
        }),
      }
    : {}),

  informationChange: yup.object().shape({
    principalAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          // state: yup.string().required('Required').test('validState', `This address must be in the state of ${sitePhrasing.state}`,
          //   (value: any) => {
          //     if (forcedStatePrincipal[sitePhrasing.state]) {
          //       if (!value) { return false }
          //       return forcedStatePrincipal[sitePhrasing.state][value.toLowerCase()]
          //     }
          //     return true
          //   }),
          state: yup.string().required("Required"),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    mailingAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup.string().required("Required"),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    registeredAgent: yup.object().shape({
      agentChange: yup.string().required("Required"),
      newAgent: yup.string().when("agentChange", {
        is: "yes",
        then: yup.string().required("Required"),
      }),
      agentAddressChange: yup.string().required("Required"),
      newAddress: yup.object().when("agentAddressChange", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedRegistered[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedRegistered[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),

    ...(sitePhrasing.state === "Georgia"
      ? {
          emailAddress: yup.object().shape({
            emailAddressChange: yup.string().required("Required"),
            newEmailAddress: yup.string().when("emailAddressChange", {
              is: "yes",
              then: yup
                .string()
                .email("Please enter a valid email")
                .required("Required"),
            }),
          }),
        }
      : {}),

    ...(sitePhrasing.state === "Indiana"
      ? {
          emailAddress: yup.object().shape({
            emailAddressChange: yup.string().required("Required"),
            newEmailAddress: yup.string().when("emailAddressChange", {
              is: "yes",
              then: yup
                .string()
                .email("Please enter a valid email")
                .required("Required"),
            }),
          }),
        }
      : {}),

    authorizedPerson: yup.object().shape({
      authorizedPersonChange: yup.string().required("Required"),
      removingAgents: yup.string().when("authorizedPersonChange", {
        is: "yes",
        then: yup.string().required("Required"),
      }),
      removedAgents: yup
        .array()
        .when(["authorizedPersonChange", "removingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
      addingAgents: yup.string().when("authorizedPersonChange", {
        is: "yes",
        then: yup.string().required("Required"),
      }),
      addedAgents: yup
        .array()
        .when(["authorizedPersonChange", "addingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
    }),
  }),

  iAmAuthorized: yup.bool().required().oneOf([true], "Must be checked"),
  annualAgree: yup.bool().required().oneOf([true], "Must be checked"),
  electronicSignature: yup.string().required("Required"),
  title: yup.string().required("Required"),
});
