import { sitePhrasing } from "../../../../Language/languageHandler";
import { updateProductPrice } from "../../../../Redux/Shopping/shoppingHelpers";
import {
  updateCart,
  updateProductCartInfo,
} from "../../../../Redux/Shopping/shoppingSlice";
import { useAppDispatch } from "../../../../Redux/store";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import {
  ArrayEntryFieldType,
  ArrayFieldInput,
} from "../../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import ImportantMessage from "../../../ImportantMessage/ImportantMessage";
import { iAgreeText, termAndConditionAcceptText, yesOrNoValues } from "../PurchaseConstants";
import { forcedRegistered } from "../PurchaseFormHelpers";

export default function TennesseeAnnualReportFields(props: {
  errors: any;
  touched: any;
  values: any;
  selectedProducts: any;
  setFieldValue: any;
}) {
  const { errors, touched, values, selectedProducts, setFieldValue } = props;

  const dispatch = useAppDispatch();

  const updateShoppingCart = (product: string) => {
    dispatch(updateCart(product));
  };

  const updateCartInfo = (product: string) => {
    dispatch(updateProductCartInfo(product));
  };

  return (
    <>
      <h3>Do you need to change any of the following information?</h3>
      <RadioInputField
        fieldName={"annualReport.informationChange.principalAddress.changed"}
        fieldLabel={"Principal Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.principalAddress.changed ===
        "yes" && (
        <AddressInputFieldGroup
          fieldName={
            "annualReport.informationChange.principalAddress.newAddress"
          }
          errors={errors}
          touched={touched}
        />
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.mailingAddress.changed"}
        fieldLabel={"Mailing Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.mailingAddress.changed ===
        "yes" && (
        <AddressInputFieldGroup
          fieldName={"annualReport.informationChange.mailingAddress.newAddress"}
          errors={errors}
          touched={touched}
        />
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
        fieldLabel={"Registered Agent?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
        onChange={(e: any) => {
          if (e.target.value === "no") {
            if (selectedProducts["registeredAgentChanges"]) {
              updateShoppingCart("registeredAgentChanges");
            }
          } else {
            if (!selectedProducts["registeredAgentChanges"]) {
              updateShoppingCart("registeredAgentChanges");
            }
          }
          setFieldValue(
            "annualReport.informationChange.registeredAgent.agentChange",
            e.target.value
          );
        }}
      />
      {values.annualReport.informationChange.registeredAgent.agentChange ===
        "yes" && (
        <>
          <ImportantMessage>
            <b>IMPORTANT NOTICE:</b>{" "}
            <u>
              Changing your registered agent requires an additional $20 fee and
              has been added to your total cost.
            </u>
          </ImportantMessage>

          <RadioInputField
            fieldName={
              "annualReport.informationChange.registeredAgent.agentType"
            }
            fieldLabel={
              "Is the new registered agent an individual or an organization?"
            }
            errors={errors}
            required={true}
            touched={touched}
            options={[
              { value: "individual", label: "Individual" },
              { value: "organization", label: "Organization" },
            ]}
          />

          {values.annualReport.informationChange.registeredAgent.agentType ===
            "individual" && (
            <>
              <TextInputField
                fieldName={
                  "annualReport.informationChange.registeredAgent.firstName"
                }
                fieldLabel={"First Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"First Name"}
              />
              <TextInputField
                fieldName={
                  "annualReport.informationChange.registeredAgent.lastName"
                }
                fieldLabel={"Last Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Last Name"}
              />
            </>
          )}

          {values.annualReport.informationChange.registeredAgent.agentType ===
            "organization" && (
            <>
              <TextInputField
                fieldName={
                  "annualReport.informationChange.registeredAgent.businessName"
                }
                fieldLabel={"Business Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Business Name"}
              />
            </>
          )}

          {(values.annualReport.informationChange.registeredAgent.agentType ===
            "organization" ||
            values.annualReport.informationChange.registeredAgent.agentType ===
              "individual") && (
            <AddressInputFieldGroup
              fieldName={
                "annualReport.informationChange.registeredAgent.newAddress"
              }
              errors={errors}
              touched={touched}
              fieldMessages={{
                state: forcedRegistered[sitePhrasing.state]
                  ? `Your registered agent must be located in ${sitePhrasing.state}.`
                  : ``,
              }}
              disableFields={{
                state: forcedRegistered[sitePhrasing.state] ? true : false,
              }}
            />
          )}
        </>
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.llcManager.llcManagerChange"}
        fieldLabel={"LLC Managers?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.llcManager.llcManagerChange ===
        "yes" && (
        <>
          <RadioInputField
            fieldName={
              "annualReport.informationChange.llcManager.removingAgents"
            }
            fieldLabel={`Are you removing any LLC Managers?`}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.llcManager.removingAgents ===
            "yes" && (
            <ArrayFieldInput
              fieldName={
                "annualReport.informationChange.llcManager.removedAgents"
              }
              values={
                values.annualReport.informationChange.llcManager.removedAgents
              }
              blankEntryFn={blankLlcManagerFn}
              fields={llcManagerFields}
              touched={touched}
              errors={errors}
              removeText={"- Click to remove entry"}
              addText={"+ Click to add an entry"}
            />
          )}

          <RadioInputField
            fieldName={"annualReport.informationChange.llcManager.addingAgents"}
            fieldLabel={`Are you adding any LLC Managers?`}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.llcManager.addingAgents ===
            "yes" && (
            <ArrayFieldInput
              fieldName={
                "annualReport.informationChange.llcManager.addedAgents"
              }
              values={
                values.annualReport.informationChange.llcManager.addedAgents
              }
              blankEntryFn={blankLlcManagerFn}
              fields={llcManagerFields}
              touched={touched}
              errors={errors}
              removeText={"- Click to remove entry"}
              addText={"+ Click to add an entry"}
            />
          )}
        </>
      )}

      <TextInputField
        fieldName={"annualReport.membersWithStake"}
        fieldLabel={"Number of members who manage the business"}
        errors={errors}
        touched={touched}
        required={true}
        fieldType={"number"}
        placeholder={"Number of members who manage the business"}
        onChange={(e: any) => {
          if (e.target.value > 6) {
            const price = Math.min((e.target.value - 6) * 5000, 300000);
            updateProductPrice("additionalMembers", price);
            updateCartInfo("additionalMembers");
          } else {
            if (selectedProducts["additionalMembers"]) {
              updateShoppingCart("additionalMembers");
            }
          }
          setFieldValue("annualReport.membersWithStake", e.target.value);
        }}
      />
      {values.annualReport.membersWithStake > 6 && (
        <ImportantMessage>
          <b>IMPORTANT NOTICE:</b> There is no additional charge for businesses
          with 6 or less members.&nbsp;
          <u>
            Any business which has 7 or more members is required to pay an
            additional $50 per additional member after 6, at a maximum of
            $3000.&nbsp;
            <b>
              The accumulated fee based on your entry has been added to your
              total cost.
            </b>
          </u>
        </ImportantMessage>
      )}

      <CheckboxInputField
        fieldName={"annualReport.annualAgree"}
        fieldLabel={`With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing Tennessee Business Filing Center as a "Third Party Designee" to renew my business on my behalf using the information I provided.`}
        touched={touched}
        errors={errors}
      />

      <CheckboxInputField
        fieldName={"annualReport.tellingTruth"}
        fieldLabel={`By checking the box below, you certify the following:`}
        touched={touched}
        errors={errors}
        customLabel={() => (
          <>
            By checking the box below, you certify the following: <br />
            <ul style={{ margin: "10px 0" }}>
              <li>
                I understand that the information submitted electronically by me
                will be used to generate an annual report for this company that
                will be filed with the Secretary of State;
              </li>
              <li>
                I am authorized to submit an annual report for this company;
              </li>
              <li>
                I intend and agree that the electronic submission of the
                information set forth herein constitutes my signature of the
                annual report;
              </li>
              <li>The information is correct; and</li>
              <li>
                I understand that the submission of fraudulent information may
                subject the company to administrative dissolution/revocation
                proceedings and may also subject me to criminal prosecution.
              </li>
            </ul>
          </>
        )}
      />

      <CheckboxInputField
        fieldName={"iAgree"}
        fieldLabel={iAgreeText}
        touched={touched}
        errors={errors}
      />

      <CheckboxInputField
        fieldName={"termAndConditionAgree"}
        fieldLabel={
          <span
            dangerouslySetInnerHTML={{ __html: termAndConditionAcceptText }}
          />
        }
        touched={touched}
        errors={errors}
      />
      {/* <CheckboxInputField
        fieldName={"annualReport.iAmAuthorized"}
        fieldLabel={
          'The individual "signing" this document affirms that the facts stated herein are true. This signature must be that of the individual "signing" this document electronically or be made with the full knowledge and permission of the individual, otherwise it constitutes forgery.'
        }
        touched={touched}
        errors={errors}
      /> */}

      <TextInputField
        fieldName={"annualReport.electronicSignature"}
        fieldLabel={"Client Agreement & Signature"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Electronic Signature"}
        customClass={"signature-font"}
      />

      <TextInputField
        fieldName={"firstName"}
        fieldLabel={"First Name"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"First Name"}
      />

      <TextInputField
        fieldName={"lastName"}
        fieldLabel={"Last Name"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Last Name"}
      />

      <TextInputField
        fieldName={"annualReport.title"}
        fieldLabel={"Title"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Title"}
      />

      <TextInputField
        mask={[
          "(",
          /[1-9]/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        fieldName={"phoneNumber"}
        fieldLabel={"Phone Number"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Phone Number"}
      />

      <TextInputField
        fieldName={"email"}
        fieldLabel={"Email"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Email"}
      />
    </>
  );
}

export const blankLlcManagerFn = () => ({
  name: "",
  title: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
});

export const llcManagerFields: ArrayEntryFieldType[] = [
  {
    fieldType: "text",
    fieldName: "name",
    fieldLabel: "Name",
    placeholder: "Name",
    required: true,
  },
  // {
  //   fieldType: 'text',
  //   fieldName: 'title',
  //   fieldLabel: 'Title',
  //   placeholder: 'Title',
  //   required: true,
  // },
  {
    fieldType: "text",
    fieldName: "address",
    fieldLabel: "Street",
    placeholder: "Street",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "address2",
    fieldLabel: "Apt #",
    placeholder: "Apt #",
  },
  {
    fieldType: "text",
    fieldName: "city",
    fieldLabel: "City",
    placeholder: "City",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "state",
    fieldLabel: "State",
    placeholder: "State",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "zip",
    fieldLabel: "Zip",
    placeholder: "Zip",
    required: true,
  },
];
